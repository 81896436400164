import React from "react";
import "./policy.scss";

export default function Terms() {
    React.useEffect(() => {
        document.title = "Find my EV – Terms and Conditions";
    }, []);
    return (
        <div className="terms-container">
            <h2>Terms and Conditions of Use</h2>
            <p>
                Find my EV is a website and service designed to assist you in selecting
                an EV that suits your needs.
                <ul>
                    <li>
                        While we endeavour to make sure all information used in providing
                        that service to you is correct, we do not guarantee the suitability
                        of any vehicle recommendation for your specific circumstances.
                    </li>
                    <li>We are not affiliated with any vehicle manufacturer or vehicle supplier.</li>
                    <li>
                        Where our website directs you to a third party website we do not
                        warrant that third party content as fit for purpose.
                    </li>
                    <li>You may unsubscribe from communications with us at any time. </li>
                </ul>
            </p>
            <h3>Referral Partners</h3>
            <p>
                FindMyEV may receive a commission for any funded loans.
                Finance provided by FindMyEV’s finance partner, Driva Pty Ltd,
                Australian Credit License No. 531492, ABN 37 636 659 160
            </p>
            <h3>Enquiries</h3>
            <p>
                If you have any questions about our Privacy Policy and Terms and
                Conditions of Use, please contact us at:
            </p>
            <h4>Find my EV</h4>
            <h4>ev@findmyev.com.au</h4>
        </div>
    );
}
