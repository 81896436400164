import React, { useRef, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import "./BookDrive.scss";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../config";
import { connect } from "react-redux";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    opacity: 1,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

//dialog title component with close button
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


//Modal for contact us
function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(props.open);
  const [name, setName] = React.useState("");
  const [nameError, setNameError] = React.useState(false);
  const [msgError, setMsgError] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [recaptchaError, setRecapcthaError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [isValidCaptcha, setCaptcha] = React.useState(false);
  let captchaDemo = useRef();

  useEffect(() => {
    document.title = "Find my EV – Contact us";
    document.querySelector("meta[name='robots']").content = "all";
    document.querySelector("meta[property='og:title']").content =
      "Find my EV – Contact us";
    document.querySelector("meta[property='og:description']").content =
      "Contact us for more information or to provide feedback about the site.";
    document.querySelector("meta[name='twitter:description']").content =
      "Contact us for more information or to provide feedback about the site.";
    document.querySelector("meta[name='description']").content =
      "Contact us for more information or to provide feedback about the site. ";
  }, []);

 
  const handleClose = () => {
    setOpen(false);
    props.handleCloseModal(false);
  };

  const onLoadRecaptcha = () => {
    if (captchaDemo) {
    }
  };
  const verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    console.log(recaptchaToken, "<= your recaptcha token");
    if (recaptchaToken !== "") {
      setCaptcha(true);
      setRecapcthaError(false);
    }
  };
  const handleName = (e) => {
    setName(e.target.value);
    if (
      e.target.value.length >= 2 &&
      nameError &&
      e.target.value.length <= 254
    ) {
      setNameError(false);
    }
  };
  const isName = (val) => {
    let regName = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
    if (!regName.test(val) && val !== "") {
      setNameError(true);
    } else if ((val.length < 2 || val.length > 254) && val !== "") {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
    console.log(e.target.value.length);
    if (
      (e.target.value.length >= 2 && e.target.value.length <= 800) ||
      msgError
    ) {
      setMsgError(false);
    }
  };

  const isValidMessage = (e) => {
    if ((e.length < 2 || e.length > 800) && e !== "") {
      setMsgError(true);
    } else {
      setMsgError(false);
    }
  };
  const handleMessageModal = (bool) => {
    isEmail(email);
    isName(name);
    isValidMessage(message);
    let request = {
      name: name,
      suburb: props.placeObject,
      email: email,
      message: message,
    };
    if (
      name &&
      email &&
      !error &&
      !nameError &&
      message &&
      !msgError &&
      isValidCaptcha
    ) {
      sendMessage(request);
      setOpen(false);
      props.handleChange(false);
    }
    if (email === "") {
      setError(true);
    }
    if (name === "") {
      setNameError(true);
    }
    if (message === "") {
      setMsgError(true);
    }
    if (!isValidCaptcha) {
      setRecapcthaError(true);
    }
  };

  const isEmail = (val) => {
    if (val !== "" && error) {
      setError(false);
    }
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      console.log("Invalid Email");
      setError(true);
    } else {
      console.log("valid");
      setEmail(val);
      setError(false);
    }
    if (val === "") {
      setError(false);
    }
  };

  const handleEmail = (val) => {
    if (val !== "" && error) {
      setError(false);
    }
    setEmail(val);
  };

  const sendMessage = (filter) => {
    let url = config.contactUsApi;
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filter),
    })
      .then((res) => {
        return res.json();
      })
      .then((response, error) => {
        console.log(response);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          className="book-a-test-drive"
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Get in touch
        </DialogTitle>
        <div>
          <p className="fill-in-the-contact">
            Leave your details along with your message, and we’ll get back to
            you shortly.
          </p>
          <p className="input-container">
            <label>Name</label>
            <input
              type="text"
              placeholder="Enter your name"
              onChange={(e) => handleName(e)}
              onBlur={(e) => isName(e.target.value)}
              required
            ></input>
            <span className={nameError ? "input-error" : "no-error"}>
              {name.length < 2 || name.length > 254
                ? name === ""
                  ? "Name field cannot be empty"
                  : "Name length should be in between 2 to 254"
                : "Please enter valid name"}
            </span>
          </p>
          <p className="input-container">
            <label>Email</label>
            <input
              type="text"
              placeholder="Enter your email"
              onBlur={(e) => isEmail(e.target.value)}
              onChange={(e) => handleEmail(e.target.value)}
              required
            ></input>
            <span className={error ? "input-error" : "no-error"}>
              {email === ""
                ? "Email field cannot be empty"
                : "Please enter a valid email"}
            </span>
          </p>
          <p className="input-container">
            <label>Message</label>
            <textarea
              placeholder="Enter your message"
              onChange={(e) => handleMessage(e)}
              onBlur={(e) => isValidMessage(e.target.value)}
            >
              {" "}
            </textarea>
            <span className={msgError ? "input-error" : "no-error"}>
              {message.length > 800
                ? "Character length should be in between 2 to 800"
                : "Please enter your message"}
            </span>
          </p>
          <p className="input-container">
            <ReCAPTCHA
              ref={(el) => {
                captchaDemo = el;
              }}
              className="g-recaptcha"
              size="normal"
              data-theme="dark"
              render="explicit"
              sitekey="6LdKWDQaAAAAAPIq6VLvyxxsCg4rD6su9iQUhqRb"
              onloadCallback={onLoadRecaptcha}
              onChange={verifyCallback}
            />
            <span
              className={recaptchaError ? "input-error mb-screen" : "no-error"}
            >
              Please select the checkbox
            </span>
          </p>
        </div>

        <button
          className="modal-submit"
          onClick={() => handleMessageModal(true)}
        >
          Submit
        </button>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    placeObject: state.placeObject,
  };
}

export default connect(mapStateToProps)(CustomizedDialogs);
