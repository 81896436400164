import React from "react";
import CoupePng from "../../../images/Coupe.png";
import HatchPng from "../../../images/Hatch.png";
import SedanPng from "../../../images/Sedan.png";
import SUVPng from "../../../images/SUV.png";
import WagonPng from "../../../images/wagon.png";
import { connect } from "react-redux";
import {  withRouter } from "react-router-dom";
import {
  updateCarArray,
  updateCarType,
  updatePluginHybrid,
} from "../../../redux/actions";

//component for selecting the required car type
class Cartype extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Find my EV – What sort of car do you want?";
    document.querySelector("meta[name='robots']").content = "noindex";
    document.querySelector("meta[property='og:title']").content =
      "Find my EV – What sort of car do you want?";
    document.querySelector("meta[property='og:description']").content =
      "What sort of car do you want? Select a body style. Happy with a used car? Would you consider a PHEV?";
    document.querySelector("meta[name='twitter:description']").content =
      "What sort of car do you want? Select a body style. Happy with a used car? Would you consider a PHEV?";
    document.querySelector("meta[name='description']").content =
      "What sort of car do you want? Select a body style. Happy with a used car? Would you consider a PHEV? ";
  }
  shouldComponentUpdate(prevProps, nextProps) {
    if (prevProps.carTypeArray !== nextProps.carTypeArray) {
      return false;
    }
    if (prevProps.whichCarType !== nextProps.whichCarType) {
      return false;
    }

    if (prevProps.plugInHybrid !== nextProps.plugInHybrid) {
      return false;
    }
  }


  //to handle the checked car images ans store them in array
  handleCheckedCarType = (item) => {
    if (this.props.carTypeArray.includes(item)) {
      let temp = [...this.props.carTypeArray];
      temp = temp.filter((element) => element !== item);
      console.log(temp);
      this.props.changeCarArray(temp);
    } else {
      let temp = [...this.props.carTypeArray];
      temp.push(item);
      console.log(temp);
      this.props.changeCarArray(temp);
    }
  };

  handleCarType = (name) => {
    this.props.changeCarType(name);
  };

  handleChange = (bool) => {
    this.props.changeCarPlugin(bool);
  };
  render() {
    return (
      <div className="hm-header">
        <h4>
          What <b>type of car</b> are you after?
        </h4>
        <div className="image-container">
          <div className="car-img-section">
            <div className="car-img">
              <input
                type="checkbox"
                className="check-box"
                id="myCheckbox1"
                checked={this.props.carTypeArray.includes("Hatch")}
                onChange={() => this.handleCheckedCarType("Hatch")}
              />
              <label htmlFor="myCheckbox1" tabIndex="0">
                <img src={HatchPng} alt="#"/>
                <div className="car-name">
                  <h6>Hatch</h6>
                </div>
              </label>
            </div>
          </div>
          <div className="car-img-section">
            <div className="car-img">
              <input
                type="checkbox"
                className="check-box"
                id="myCheckbox2"
                checked={this.props.carTypeArray.includes("SUV")}
                onChange={() => this.handleCheckedCarType("SUV")}
              />
              <label htmlFor="myCheckbox2" tabIndex="0">
                <img src={SUVPng} alt="#" />
                <div className="car-name">
                  <h6>SUV</h6>
                </div>
              </label>
            </div>
          </div>
          <div className="car-img-section">
            <div className="car-img" alt="#">
              <input
                type="checkbox"
                className="check-box"
                id="myCheckbox3"
                checked={this.props.carTypeArray.includes("Sedan")}
                onChange={() => this.handleCheckedCarType("Sedan")}
              />
              <label htmlFor="myCheckbox3" tabIndex="0">
                <img src={SedanPng} alt="#" />
                <div className="car-name">
                  <h6>Sedan</h6>
                </div>
              </label>
            </div>
          </div>
          <div className="car-img-section">
            <div className="car-img">
              <input
                type="checkbox"
                className="check-box"
                id="myCheckbox4"
                checked={this.props.carTypeArray.includes("Wagon")}
                onChange={() => this.handleCheckedCarType("Wagon")}
              />
              <label htmlFor="myCheckbox4" tabIndex="0">
                <img src={WagonPng}  alt="#"/>
                <div className="car-name">
                  <h6>Wagon</h6>
                </div>
              </label>
            </div>
          </div>
          <div className="car-img-section">
            <div className="car-img">
              <input
                type="checkbox"
                className="check-box"
                id="myCheckbox5"
                checked={this.props.carTypeArray.includes("Coupe")}
                onChange={() => this.handleCheckedCarType("Coupe")}
              />
              <label htmlFor="myCheckbox5" tabIndex="0">
                <img src={CoupePng}  alt="#" />
                <div className="car-name">
                  <h6>Coupe</h6>
                </div>
              </label>
            </div>
          </div>
        </div>

        <div className="car-type-section">
          <button
            tabIndex="0"
            className={
              this.props.whichCarType === ""
                ? "button-active"
                : "button-inactive"
            }
            onClick={() => this.handleCarType("")}
          >
            New & Used
          </button>
          <button
            tabIndex="0"
            className={
              this.props.whichCarType === "new"
                ? "button-mid-active"
                : "button-mid-inactive"
            }
            onClick={() => this.handleCarType("new")}
          >
            New
          </button>
          <button
            tabIndex="0"
            className={
              this.props.whichCarType === "used"
                ? "button-last-active"
                : "button-last-inactive"
            }
            onClick={() => this.handleCarType("used")}
          >
            Used
          </button>
        </div>
        <p className="would-you-consider-a">
          Would you consider a plug-in hybrid?
        </p>
        <p className="plug-in-hybrids-phe">
          Plug-in hybrids (PHEVs) are perfect if you have a weekday requirement
          within the EV only range, but want more flexibility at weekends and
          longer trips. If you aren't prepared to plug in every night, a PHEV is
          not for you.
        </p>
        <div className="rectangle-button btn-col-02">
          <button
            tabIndex="0"
            className={
              this.props.plugInHybrid ? "button-active" : "button-inactive"
            }
            onClick={() => this.handleChange(true)}
          >
            Yes
          </button>
          <button
            tabIndex="0"
            className={
              this.props.plugInHybrid
                ? "button-right-inactive"
                : "button-right-active"
            }
            onClick={() => this.handleChange(false)}
          >
            No
          </button>
        </div>
        <p className="by-clicking-find-my">
          By clicking ‘Find my EV’, you agree to and accept the{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/privacy-policy"
            tabIndex="0"
          >
            Privacy Policy
          </a>{" "}
          &{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="/terms-and-conditions"
            tabIndex="0"
          >
            Terms and Conditions
          </a>{" "}
          of use.
        </p>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    carTypeArray: state.carTypeArray,
    whichCarType: state.whichCarType,
    plugInHybrid: state.plugInHybrid,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCarArray: (prop) => dispatch(updateCarArray(prop)),
    changeCarType: (prop) => dispatch(updateCarType(prop)),
    changeCarPlugin: (prop) => dispatch(updatePluginHybrid(prop)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Cartype)
);
