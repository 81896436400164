import React from "react";
import "./policy.scss";

export default function Policy() {
  React.useEffect(() => {
    document.title = "Find my EV – Privacy Policy";
  }, []);
  return (
    <div className="terms-container">
      <h2>Privacy Policy </h2>
      <p>
        <b>Find my EV</b> is committed to providing quality services to you and
        this policy outlines our ongoing obligations to you in respect of how we
        manage your Personal Information and Data as well as the Terms and
        Conditions of the use of our website.
      </p>
      <h3>What is Personal Information and Data and why do we collect it?</h3>
      <p>
        Personal Information is information that identifies an individual.
        Examples of Personal Information and Data we collect include: names,
        addresses, email addresses and phone numbers.
        <br />
        Data is information primarily related to your driving requirements but
        may include your vehicle preferences, regular destinations and
        information about how you charge.
        <br />
        This Personal Information and Data is obtained primarily through our
        website and direct interaction with you.
        <br />
        We collect your Personal Information and Data for the primary purpose of
        providing our services to you, providing information to our clients and
        marketing.
        <br />
        This policy explains why we are collecting the information and how we
        plan to use it.
      </p>
      <h3>Sensitive Information</h3>
      <p>
        Sensitive information is defined in the Privacy Act to include
        information or opinion about such things as an individual's racial or
        ethnic origin, political opinions, membership of a political
        association, religious or philosophical beliefs, membership of a trade
        union or other professional body, criminal record or health information.
        <br />
        We have no intention of collecting sensitive information about you, but
        if we do, it will be handled in the following way.
        <br />
        Sensitive information will be used by us only:
        <br></br>
        <ul>
          <li>For the primary purpose for which it was obtained</li>
          <li>With your consent; or where required or authorised by law.</li>
        </ul>
      </p>
      <h3>Third Parties</h3>
      <p>
        Where reasonable and practicable to do so, we will collect your Personal
        Information and Data only from you. However, in some circumstances we
        may be provided with information by third parties. This third party
        information will be treated by us as though you provided it directly.
      </p>
      <h3>Disclosure of Personal Information</h3>
      <p>
        Your Personal Information may be disclosed in a number of circumstances
        including the following:
        <br />
        <ul>
          <li>Third parties where you consent to the use or disclosure; and</li>
          <li>Where required or authorised by law.</li>
          <li>
            We will never disclose your home address or your email address
            without your express consent. Specifically, if you request us to
            arrange a test drive, you will be provided with the contact details
            of the dealer’s representative, but they will only know you by your
            preferred name.
          </li>
        </ul>
      </p>
      <h3>Disclosure of Data</h3>
      <p>
        Your Data may be disclosed to third parties on the following basis:
        <br></br>
        <ul>
          <li>
            In a manner which will not allow you to be identified as an
            individual or where you live.
          </li>
        </ul>
      </p>
      <h3>Security of Personal Information and Data</h3>
      <p>
        Your Personal Information and Data is stored in a manner that reasonably
        protects it from misuse and loss and from unauthorized access,
        modification or disclosure.
        <br />
        When your Personal Information and Data is no longer needed for the
        purpose for which it was obtained, we will take reasonable steps to
        destroy or permanently de-identify your Personal Information.
      </p>
      <h3>Updates</h3>
      <p>
        This Policy may change from time to time and is available on our
        website.
      </p>
      <h3>Enquiries</h3>
      <p>
        If you have any questions about our Privacy Policy and Terms and
        Conditions of Use, please contact us at:
      </p>
      <h4>Find my EV</h4>
      <h4>ev@findmyev.com.au</h4>
    </div>
  );
}
