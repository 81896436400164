import React, { useRef, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import SearchInput from "../../landingpage/SearchInput";
import "./BookDrive.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { bookForTestDrive } from "../../../redux/actions";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    opacity: 1,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//modal for getting information for booking a test drive
function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(props.open);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [nameError, setNameError] = React.useState(false);
  const [recaptchaError, setRecapcthaError] = React.useState(false);
  const [isValidCaptcha, setCaptcha] = React.useState(false);

  useEffect(() => {
    document.title = "Find my EV – Arrange a complimentary test drive";
    document.querySelector("meta[name='robots']").content = "noindex";
    document.querySelector("meta[property='og:title']").content =
      "Find my EV – Arrange a complimentary test drive";
    document.querySelector("meta[property='og:description']").content =
      "We will find you a dealer who has an EV for you to test drive. We'll also tell them you aren't interested in being sold an ICE car instead.";
    document.querySelector("meta[name='twitter:description']").content =
      "We will find you a dealer who has an EV for you to test drive. We'll also tell them you aren't interested in being sold an ICE car instead.";
    document.querySelector("meta[name='description']").content =
      "We will find you a dealer who has an EV for you to test drive. We'll also tell them you aren't interested in being sold an ICE car instead. ";
  }, []);

  let captchaDemo = useRef();

  const handleClose = () => {
    setOpen(false);
    props.handleCloseModal(false);
  };

  const verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    if (recaptchaError) {
      setRecapcthaError(false);
      console.log("done");
    }
    if (recaptchaToken !== "") {
      setCaptcha(true);
      setRecapcthaError(false);
    }
  };

  const handleName = (e) => {
    setName(e.target.value);
    if (
      e.target.value.length >= 2 &&
      nameError &&
      e.target.value.length <= 254
    ) {
      setNameError(false);
    }
  };

  const isName = (val) => {
    let regName = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
    if (!regName.test(val) && val !== "") {
      setNameError(true);
    } else if ((val.length < 2 || val.length > 254) && val !== "") {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };


  //for api calling after submitting the data
  const handleMessageModal = (bool) => {
    isEmail(email);
    isName(name);
    let tripMultiplier = {
      "Single return trip": 2,
      "Back and forth": 3.5,
      "All over the place": 6,
    };

    let filter = {
      homeCharge: props.parkingPlace,
      r1: parseInt(props.weekdayRange * tripMultiplier[props.perWeekdayTrip]),
      r2: parseInt(props.weekendRange * tripMultiplier[props.perWeekendTrip]),
      r3: parseInt((props.distanceBetweenHomeToFurthest.value / 1000) * 2),
      bodyStyle: props.carTypeArray.toString(),
      newOrUsed: props.whichCarType,
      suburb: props.testDriveLocation,
      classification: props.plugInHybrid,
      name: name,
      email: email,
      vehicle: props.carName,
      evxScore: `${props.exvScore}`,
    };
    console.log(filter);
    if (
      filter.r1 &&
      filter.r2 &&
      filter.r3 &&
      filter.vehicle &&
      filter.evxScore &&
      filter.bodyStyle.length !== 0 &&
      Object.keys(filter.suburb).length > 0 &&
      name &&
      email &&
      !error &&
      !nameError &&
      isValidCaptcha
    ) {
      props.updateForBooking(filter);
      setOpen(false);
      props.handleChange(false);
    }
    if (email === "") {
      setError(true);
    }
    if (name === "") {
      setNameError(true);
    }
    if (!isValidCaptcha) {
      setRecapcthaError(true);
    }
  };

  //to handle the input email
  const isEmail = (val) => {
    if (val !== "" && error) {
      setError(false);
    }
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      console.log("Invalid Email");

      setError(true);
    } else {
      console.log("valid");
      setEmail(val);

      setError(false);
    }
    if (val === "") {
      setError(false);
    }
  };

  const handleEmail = (val) => {
    if (val !== "" && error) {
      setError(false);
    }
    setEmail(val);
  };

  console.log("error", recaptchaError);
  let suburb = [];
  if (Object.keys(props.testDriveLocation).length > 0) {
    suburb = props.testDriveLocation.address_components.filter((ele) => {
      if (ele.types.includes("locality")) {
        return ele;
      }
    });
    //If locality is not present in the object
    if (suburb.length === 0) {
      suburb.push({
        short_name: props.testDriveLocation.formatted_address.split(",")[0],
      });
    }
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        disableBackdropClick
      >
        <DialogTitle
          className="book-a-test-drive"
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Arrange a test drive
        </DialogTitle>
        <div>
          <p className="fill-in-the-contact">
            Fill in the contact details and we will be in touch shortly to
            clarify your requirements and arrange a complimentary test drive at
            a dealer near you.
          </p>
          <p className="input-container">
            <label>Name</label>
            <input
              type="text"
              placeholder="Enter your name"
              onChange={(e) => handleName(e)}
              onBlur={(e) => isName(e.target.value)}
              required
            ></input>
            <span className={nameError ? "input-error" : "no-error"}>
              {name.length < 2 || name.length > 254
                ? name === ""
                  ? "Name field cannot be empty"
                  : "Name length should be in between 2 to 254"
                : "Please enter valid name"}
            </span>
          </p>
          <p className="input-container">
            <label>Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              onBlur={(e) => isEmail(e.target.value)}
              onChange={(e) => handleEmail(e.target.value)}
              required
            ></input>
            <span className={error ? "input-error" : "no-error"}>
              {email === ""
                ? "Email field cannot be empty"
                : "Please enter a valid email"}
            </span>
          </p>
          <p className="input-container">
            <label>Suburb</label>
            <SearchInput
              placeholder="Enter a suburb or postcode"
              update="testDriveLocation"
              location={suburb.length > 0 ? suburb[0].short_name : ""}
            ></SearchInput>
            <span
              className={
                Object.keys(props.testDriveLocation).length > 0
                  ? "no-error"
                  : "input-error"
              }
            >
              Please enter the location
            </span>
          </p>
          <p className="input-container ">
            <ReCAPTCHA
              ref={(el) => {
                captchaDemo = el;
              }}
              className="g-recaptcha"
              size="normal"
              data-theme="dark"
              render="explicit"
              sitekey="6LdKWDQaAAAAAPIq6VLvyxxsCg4rD6su9iQUhqRb"
              onChange={verifyCallback}
            />
            <span
              className={recaptchaError ? "input-error mb-screen" : "no-error"}
            >
              Please select the checkbox
            </span>
          </p>
        </div>

        <button
          className="modal-submit"
          onClick={() => handleMessageModal(true)}
        >
          Submit
        </button>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    activeStep: state.activeStep,
    homeLocation: state.homeLocation,
    testDriveLocation: state.testDriveLocation,
    perWeekdayTrip: state.perWeekdayTrip,
    perWeekendTrip: state.perWeekendTrip,
    furthestLocation: state.furthestLocation,
    parkingPlace: state.parkingPlace,
    distanceBetweenHomeToFurthest: state.distanceBetweenHomeToFurthest,
    weekdayRange: state.weekdayRange,
    weekendRange: state.weekendRange,
    carTypeArray: state.carTypeArray,
    whichCarType: state.whichCarType,
    plugInHybrid: state.plugInHybrid,
    placeObject: state.placeObject,
    carListResponse: state.carListResponse,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateForBooking: (props) => dispatch(bookForTestDrive(props)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomizedDialogs);
