import React from "react";
import "./Component.scss";
import SearchInput from "../../landingpage/SearchInput";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//component for home suburb page
function HomeLocation(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Find my EV – Where do you live?";
    //added meta tags for home location page
    document.querySelector("meta[name='robots']").content = "noindex";
    document.querySelector("meta[property='og:title']").content =
      "Find my EV – Where do you live?";
    document.querySelector("meta[property='og:description']").content =
      "Tell us where you live so we can start your EV search";
    document.querySelector("meta[name='twitter:description']").content =
      "Tell us where you live so we can start your EV search";
    document.querySelector("meta[name='description']").content =
      "Tell us where you live so we can start your EV search ";
  }, []);

  return (
    <div className="hm-header" id="upper-panel">
      <h4>
        What is your <b>home suburb?</b>
      </h4>
      <p className="in-case-you-dont-ke">
        In case you don’t keep your vehicle at home, enter the origin point
        where your vehicle is kept (eg. parking garage).
      </p>
      <div className="search-home">
        <SearchInput
          placeholder="Enter your home address or suburb"
          update="homeLocation"
          location={props.homeLocation}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    homeLocation: state.homeLocation,
  };
}

export default withRouter(connect(mapStateToProps)(HomeLocation));
