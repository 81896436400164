import React from "react";
import SearchInput from "./SearchInput";
import { connect } from "react-redux";
import BackImage from "../../images/Background.png";
import Logo from "../../images/findmyev_logo_189x50.svg";
import LogoTab from "../../images/findmyev_logo_170x45.svg";
import LogoMobile from "../../images/findmyev_logo_128x34.svg";
import FindMyEv from "../../images/findmyev_og.jpg";
import "./HomePage.scss";
import "./Search.scss";
import { Redirect, withRouter } from "react-router-dom";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      width: window.innerWidth,
    };
  }
  componentDidMount() {
    document.title = "Find my EV – Find your electric car or plug-in-hybrid";
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.updateDimensions);
    //meta tags are added for landing page
    document.querySelector("meta[name='robots']").content = "all";
    // document.querySelector("meta[property='og:image']").content = FindMyEv;
    document.querySelector("meta[name='keywords']").content =
      "AWD, Electric, Tesla, SUV";
    document.querySelector("meta[property='og:title']").content =
      "Find my EV – Find your electric car or plug-in-hybrid test";
    document.querySelector("meta[property='og:description']").content =
      "Want to buy an electric vehicle or plug-in hybrid and don’t know where to start? We’ll find the right BEV or PHEV for you. Let us arrange a complimentary a test drive.";
    document.querySelector("meta[name='twitter:description']").content =
      "Want to buy an electric vehicle or plug-in hybrid and don’t know where to start? We’ll find the right BEV or PHEV for you. Let us arrange a complimentary a test drive.";
    document.querySelector("meta[name='description']").content =
      "Want to buy an electric vehicle or plug-in hybrid and don’t know where to start? We’ll find the right BEV or PHEV for you. Let us arrange a complimentary a test drive.";
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  shouldComponentUpdate(prevProps,nextProps){
    if(prevProps.homeLocation!==nextProps.homeLocation){
      return false
    }
  }

  //for updating the current window width
  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  //to handle the redirection to the weekday range page after selecting the input
  handleRedirection = () => {
    if (this.props.homeLocation) {
      this.props.dispatch({ type: "UPDATE_ACTIVE_STEP", target: 1 });
      this.props.history.push("/search/weekday-range");
    }
  };
  
  render() {
    return (
      <React.Fragment>
        {this.state.redirect && (
          <Redirect to="/search/weekday-range" push></Redirect>
        )}
        <div className="main-container">
          <div
            className="screen"
            style={{ backgroundImage: "url(" + BackImage + ")" }}
          >
            <div className="scr-content">
              {this.state.width < 576 && (
                <img
                  style={{ zIndex: "1000", width: "140px" }}
                  src={LogoMobile}
                  alt="#"
                ></img>
              )}
              {this.state.width >= 576 && this.state.width < 992 && (
                <img
                  style={{ zIndex: "1000", width: "180px" }}
                  src={LogoTab}
                  alt="#"
                ></img>
              )}
              {this.state.width >= 992 && (
                <img
                  style={{ zIndex: "1000", width: "200px" }}
                  src={Logo}
                  alt="#"
                ></img>
              )}
              <h3>Which electric vehicle is right for you?</h3>
              <p>
                There is a plug-in option to suit your lifestyle. Let us find
                you finance.
              </p>
            </div>
            <div className="search-rectangle">
              <p>To start, what is your address?</p>
              <div className="sch-col-1">
                <SearchInput
                  placeholder="Enter your home address or suburb"
                  update="homeLocation"
                  location={this.props.homeLocation}
                />
                <button
                  onClick={() => this.handleRedirection()}
                  tabIndex="0"
                  className={
                    this.props.homeLocation
                      ? "active-button"
                      : "disabled-button"
                  }
                >
                  Start
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    homeLocation: state.homeLocation,
  };
}

export default withRouter(connect(mapStateToProps)(LandingPage));
