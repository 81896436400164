import React from "react";
import SearchInput from "../../landingpage/SearchInput";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//component for selecting the furthest destination
//travelled till now for showing the maximum return trip covered
function FurthestDestination(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Find my EV – What is your furthest regular destination?";
    //added meta tags for furthest destination page
    document.querySelector("meta[name='robots']").content = "noindex";
    document.querySelector("meta[property='og:title']").content =
      "Find my EV – What is your furthest regular destination?";
    document.querySelector("meta[property='og:description']").content =
      "hat is your furthest regular destination? There and back in a day.";
    document.querySelector("meta[name='twitter:description']").content =
      "hat is your furthest regular destination? There and back in a day.";
    document.querySelector("meta[name='description']").content =
      "What is your furthest regular destination? There and back in a day. ";
  }, []);

  return (
    <div className="hm-header">
      <h4>
        What's your <b>furthest regular destination?</b>
      </h4>
      <p className="com-sch">Relatives, friends, a favourite day out.</p>
      <p>Your longest return journey in a single day. At least once a month.</p>
      <div className="search-home">
        <SearchInput
          placeholder="Enter your furthest destination"
          update="furthestLocation"
          location={props.furthestLocation}
        />
        <span
          className={
            props.homeLocation === props.furthestLocation
              ? "inputbox-error"
              : "no-error"
          }
        >
          Furthest location couldn't be same as home location
        </span>
      </div>
      {props.distanceBetweenHomeToFurthest && (
        <p className="return-trip">
          <span>
            {parseInt((props.distanceBetweenHomeToFurthest.value / 1000) * 2)}{" "}
            km
          </span>{" "}
          return trip to your home.
        </p>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    furthestLocation: state.furthestLocation,
    distanceBetweenHomeToFurthest: state.distanceBetweenHomeToFurthest,
    homeLocation: state.homeLocation,
  };
}

export default withRouter(connect(mapStateToProps)(FurthestDestination));
