import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import "./BookDrive.scss";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    opacity: 1,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

//dialog title component with close button
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

//Modal for showing the success message
export default function CustomizedDialogs(props) {
  const [opennew, setOpen] = React.useState(props.open);
  console.log("open message, message modal");

  const handleClose = () => {
    setOpen(false);
    props.handleChange(false);
  };

  return (
    <div key="modal-message">
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={opennew}
      >
        <DialogTitle
          className="modal-title"
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <CheckCircleIcon
            className="check-icon"
            fontSize="middle"
          ></CheckCircleIcon>
        </DialogTitle>
        <DialogTitle
          className="book-a-test-drive"
          id="customized-dialog-title"
          onClose={handleClose}
        >
          We'll be in touch
        </DialogTitle>
        <div>
          <p className="keep-an-eye">
            {props.message
              ? props.message
              : "Keep an eye out for an email to arrange your test drive."}
          </p>
        </div>

        <button className="modal-submit2" onClick={handleClose}>
          OK
        </button>
      </Dialog>
    </div>
  );
}
