
import React from "react";
import "./App.css";
import "./all.css";
import "./fontawesome.css";
import LandingPage from "./components/landingpage/HomePage";
import Mainpanel from "./components/mainpanel/MainSection";
import { Switch, Route, Redirect ,useHistory} from "react-router-dom";
import Policy from "./policy";
import TermsAndCondition from "./TermsCondition";
import { connect } from "react-redux";


function App(props) {
  const [open, setOpen] = React.useState(false);
  const history = useHistory()
  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => setOpen(true);
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };
  React.useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
      () => {
        console.log("script loaded");
      }
    );

    window.onbeforeunload = function (e) {
      if (
        window.location.pathname === "/terms-and-conditions" ||
        window.location.pathname === "/privacy-policy"
      ) {
        return null;
      } else if (e) {
       
        e.returnValue =
          "Data will be lost if you leave the page, are you sure?";
      }
      
      return "Data will be lost if you leave the page, are you sure?";
    };
  
  
  }, []);
  return open ? (
    <div className="App">
      <Switch>
        {/* this route is for home page  */}

        <Route
          exact
          path="/"
          component={() => <LandingPage></LandingPage>}
        ></Route>
        {/* this route is for setting up the details for searching the required car */}
        <Route path="/search" component={() => <Mainpanel></Mainpanel>}></Route>
        {/* this route is for terms and condition and privacy policy */}
        <Route
          path="/terms-and-conditions"
          component={() => <TermsAndCondition></TermsAndCondition>}
        ></Route>
        <Route
          path="/privacy-policy"
          component={() => <Policy></Policy>}
        ></Route>
      </Switch>
      {props.homeLocation === ""  && props.activeStep!==0 && (
        <Redirect to="/"></Redirect>
      )}
       {props.homeLocation === "" && props.homeOrLandingPage && window.location.pathname!=="/privacy-policy" && window.location.pathname!=="/terms-and-conditions" && props.activeStep===0 && (
        <Redirect to="/"></Redirect>
      )}
    
    </div>
  ) : (
    <div></div>
  );
}


function mapStateToProps(state) {
  return {
    activeStep: state.activeStep,
    homeLocation: state.homeLocation,
    homeOrLandingPage: state.homeOrLandingPage
  };
}

export default connect(mapStateToProps)(App);
