const initialState = {
  homeLocation: "",
  placeObject: {},
  furthestLocation: "",
  furthestObject: {},
  distanceBetweenHomeToFurthest: "",
  previousActiveStep: 0,
  activeStep: 0,
  weekdayRange: 10,
  perWeekdayTrip: "Single return trip",
  weekendRange: 13,
  perWeekendTrip: "Single return trip",
  parkingPlace: true,
  carTypeArray: ["SUV"],
  whichCarType: "",
  plugInHybrid: false,
  carListResponse: {},
  testDriveLocation: {},
  object:{},
  homeOrLandingPage:true
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_HOME_LOCATION":
      return { ...state, homeLocation: action.target };
    case "UPDATE_PLACE_OBJECT":
      return { ...state, placeObject: action.target };
    case "UPDATE_FURTHEST_LOCATION":
      return { ...state, furthestLocation: action.target };
    case "UPDATE_FURTHEST_OBJECT":
      return { ...state, furthestObject: action.target };
    case "UPDATE_DISTANCE":
      return { ...state, distanceBetweenHomeToFurthest: action.target };
    case "UPDATE_ACTIVE_STEP":
      return { ...state, activeStep: action.target };
    case "UPDATE_PREVIOUS_ACTIVE_STEP":
      return { ...state, previousActiveStep: action.target };
    case "UPDATE_WEEKDAY_RANGE":
      return { ...state, weekdayRange: action.target };
    case "UPDATE_PERWEEKDAY_TRIP":
      return { ...state, perWeekdayTrip: action.target };
    case "UPDATE_WEEKEND_RANGE":
      return { ...state, weekendRange: action.target };
    case "UPDATE_PERWEEKEND_TRIP":
      return { ...state, perWeekendTrip: action.target };
    case "UPDATE_PARKING_PLACE":
      return { ...state, parkingPlace: action.target };
    case "UPDATE_CAR_ARRAY":
      return { ...state, carTypeArray: action.target };
    case "UPDATE_WHICH_CARTYPE":
      return { ...state, whichCarType: action.target };
    case "UPDATE_PLUGINHYBRID":
      return { ...state, plugInHybrid: action.target };
    case "UPDATE_CARLIST_RESPONSE":
      return { ...state, carListResponse: action.target };
    case "UPDTATE_TESTDRIVE_LOCATION":
      return { ...state, testDriveLocation: action.target };
    case "UPDATE_HOMEORLANDINGPAGE":
      return {...state, homeOrLandingPage:action.target}  

    default:
      return state;
  }
}
