import React from "react";
import { connect } from "react-redux";
import Card from "./CarCard";
import CardSmall from "./CarCardSmall";
import CardExtraSmall from "./CarCardExtraSmall";
import { Link } from "react-router-dom";
import { withRouter} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  updateActiveStep,
  getCarList,
  updateDistance,
  updateHomeLocation,
  updateFurthestLocation,
  updatePlaceObject,
  updateCarArray,
  updatePluginHybrid,
  updateCarType,
  parkingPlace,
  updatePerWeekdayTrip,
  updateWeekdayRange,
  updatePerWeekendTrip,
  updateWeekendRange,
  updateCarList,
  updatePreviousActiveStep,
  updateHomeOrLandingPage
} from "../../../redux/actions";

//Component for showing the requested result with the car details card
class CarResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      loader:false,
      loadvalue:
        Object.keys(this.props.carListResponse).length > 0 &&this.props.carListResponse.data&&
        this.props.carListResponse.data.length < 7
          ? this.props.carListResponse.data.length
          : 7,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({loader:true})
    }, 1000);
    window.scrollTo(0, 0);
    document.getElementById("right-container").scrollTo(0, 0);
    window.addEventListener("resize", this.updateDimensions);
    document.title = "Find my EV – Your top EV matches";

    //added meta tags for car result page
    document.querySelector("meta[name='robots']").content = "noindex";
    document.querySelector("meta[property='og:title']").content =
      "Find my EV – Your top EV matches";
    document.querySelector("meta[property='og:description']").content =
      "Your top EV matches. These cars will suit you based on what you told us about your driving requirments.";
    document.querySelector("meta[name='twitter:description']").content =
      "Your top EV matches. These cars will suit you based on what you told us about your driving requirments.";
    document.querySelector("meta[name='description']").content =
      "Your top EV matches. These cars will suit you based on what you told us about your driving requirments. ";
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  //for updating the current window width
  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  //to handle the back button by reducing active step
  handleBack = () => {
    this.props.history.goBack();
    this.props.nextActiveStep(this.props.activeStep - 1);
    this.props.changeCarList({});
    
  };

  //to handle the start again button
  //Here all the current state will move to its initial state
  handleStartAgain = () => {
    this.props.history.push("/search/home-location");
    this.props.nextActiveStep(0);
    this.props.changePreviousActiveStep(0)
    this.props.carListRequest({});
    this.props.changeCarList({});
    this.props.changeDistanceToBeCovered("");
    this.props.changeHomeLocation("");
    this.props.changeFurthestLocation("");
    this.props.changePlaceObject({});
    this.props.changeCarArray(["SUV"]);
    this.props.changeCarType("");
    this.props.plugInHybrid !== "false" && this.props.changeCarPlugin(false);
    this.props.parkingPlace !== "true" && this.props.changeParkingPlace(true);
    this.props.perWeekdayTrip !== "Single return trip" &&
    this.props.changePerWeekday("Single return trip");
    this.props.weekdayRange !== 10 && this.props.changeWeekdayRange(10);
    this.props.perWeekendTrip !== "Single return trip" &&
    this.props.changePerWeekend("Single return trip");
    this.props.weekendRange !== 10 && this.props.changeWeekendRange(10);
    this.props.changeHomeOrLandingPage()
  };

  //to handle load more button on every click we add 7 more cards
  handleLoadMore = () => {
    if (this.state.loadvalue < this.props.carListResponse.data.length) {
      if (this.state.loadvalue + 7 <= this.props.carListResponse.data.length) {
        this.setState({ loadvalue: this.state.loadvalue + 7 });
      } else {
        this.setState({ loadvalue: this.props.carListResponse.data.length });
      }
    }
  };

  render() {
    const { carListResponse } = this.props;
    return (
      <React.Fragment>
        {
          Object.keys(carListResponse).length>0&&this.state.loader?
        
        <div className="result-container">
          <div className="here-are-your-top-ev">
            {carListResponse.status === 200 &&
            carListResponse.data.length > 0 ? (
              <p>
                Here are your <b>top EV matches.</b>
              </p>
            ) : (
              <p>No search results</p>
            )}
          </div>
          {carListResponse.status === 200 &&
            carListResponse.data.slice(0, this.state.loadvalue).map((list) => {
              if (this.state.width < 576) {
                return <CardExtraSmall carList={list}></CardExtraSmall>;
              } else if (this.state.width < 768) {
                return <CardSmall carList={list}></CardSmall>;
              } else {
                return <Card carList={list}></Card>;
              }
            })}
          <div className="group-11">
            <button
              tabIndex="0"
              className="start-again"
              onClick={() => this.handleStartAgain()}
            >
              <i class="fal fa-undo"></i>&nbsp; Start Again
            </button>
            
              <button
                className="bck-btn"
                onClick={() => this.handleBack()}
                tabIndex="0"
              >
                <i class="fal fa-chevron-left fa-sm"></i>&nbsp;Back
              </button>
            
            {carListResponse.status === 200 && carListResponse.data.length > 0 && (
              <button
                tabIndex="0"
                className={`load-more ${
                  this.state.loadvalue ===
                    this.props.carListResponse.data.length &&
                  "load-more-disabled"
                }`}
                disabled={
                  this.state.loadvalue ===
                  this.props.carListResponse.data.length
                }
                onClick={() => this.handleLoadMore()}
              >
                <i class="fal fa-angle-down" style={{ fontSize: "24px" }}></i>
                &nbsp;&nbsp;Load more results
              </button>
            )}
          </div>
        </div>:
         <div className="wait-circle">
         <CircularProgress size={100}></CircularProgress>
         <p className="finding-your-ev-matc">Finding your EV Match</p>
       </div>}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    carListResponse: state.carListResponse,
    activeStep: state.activeStep,
    homeLocation: state.homeLocation,
    perWeekdayTrip: state.perWeekdayTrip,
    perWeekendTrip: state.perWeekendTrip,
    furthestLocation: state.furthestLocation,
    parkingPlace: state.parkingPlace,
    distanceBetweenHomeToFurthest: state.distanceBetweenHomeToFurthest,
    weekdayRange: state.weekdayRange,
    weekendRange: state.weekendRange,
    carTypeArray: state.carTypeArray,
    whichCarType: state.whichCarType,
    plugInHybrid: state.plugInHybrid,
    placeObject: state.placeObject,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    nextActiveStep: (prop) => dispatch(updateActiveStep(prop)),
    carListRequest: (prop) => dispatch(getCarList(prop)),
    changeDistanceToBeCovered: (props) => dispatch(updateDistance(props)),
    changeHomeLocation: (props) => dispatch(updateHomeLocation(props)),
    changeFurthestLocation: (props) => dispatch(updateFurthestLocation(props)),
    changePlaceObject: (props) => dispatch(updatePlaceObject(props)),
    changeCarArray: (prop) => dispatch(updateCarArray(prop)),
    changeCarType: (prop) => dispatch(updateCarType(prop)),
    changeCarPlugin: (prop) => dispatch(updatePluginHybrid(prop)),
    changeParkingPlace: (prop) => dispatch(parkingPlace(prop)),
    changePerWeekday: (prop) => dispatch(updatePerWeekdayTrip(prop)),
    changeWeekdayRange: (prop) => dispatch(updateWeekdayRange(prop)),
    changePerWeekend: (prop) => dispatch(updatePerWeekendTrip(prop)),
    changeWeekendRange: (prop) => dispatch(updateWeekendRange(prop)),
    changeCarList: (prop) => dispatch(updateCarList(prop)),
    changePreviousActiveStep:(prop)=>dispatch(updatePreviousActiveStep(prop)),
    changeHomeOrLandingPage:() =>dispatch(updateHomeOrLandingPage(false))
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CarResult)
);
