import React from "react";
import "./ResultPage.scss";
import "./CollapsePanel.scss";
import CommentImage from "../../../images/comment.png";
import CircularProgress from "./Progress";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import SentimentDissatisfiedRoundedIcon from "@material-ui/icons/SentimentDissatisfiedRounded";
import SentimentSatisfiedRoundedIcon from "@material-ui/icons/SentimentSatisfiedRounded";
import Modal from "./BookDrive";
import MessageModal from "./MessageModal";
import ChargingPng from "../../../images/charging.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontFamily: "DM Sans",
    fontSize: "14px",
    width: "210px",
  },
}));

// car details card for more than 768px screen
function Card(props) {
  const { carList } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
  const [tooltipIsOpen2, setTooltipIsOpen2] = React.useState(false);
  const ref = React.useRef(null);
  const ref2 = React.useRef(null);

  const classes = useStylesBootstrap();

  //to handle collapsible panel state
  const onToggle = (bool) => {
    setIsOpen(bool);
  };

  //to handle the arrange test drive modal
  // no longer used - replaced with openUrlInNewTab
  const handleModal = (bool) => {
    setModalOpen(bool);
    setOpenMessage(!bool);
  };

  const openUrlInNewTab = (url) => {
    window.open(url, '_blank')
  }

  //to handle the ouside click for tooltip
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setTooltipIsOpen(false);
    }
    if (ref2.current && !ref2.current.contains(event.target)) {
      setTooltipIsOpen2(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  //to handle the closing of arrange test drive modal
  const closeModal = (bool) => {
    setModalOpen(bool);
  };

  //to handle the success message modal
  const handleMessageModal = (bool) => {
    setOpenMessage(bool);
  };

  //for converting seconds to appropriate time for homeChargeTime slot
  const secondsToHms = (d) => {
    d = Number(d);
    var h = parseInt(d / 3600);
    var m = parseInt((d % 3600) / 60);
    var mDisplay = m > 9 ? m : "0" + m;
    var time = h + ":" + mDisplay;
    var final = time === "1:00" ? time + " hr" : time + " hrs";
    return final;
  };

  return (
    <div className="card-container">
      {modalOpen && (
        <Modal
          open={true}
          handleChange={handleModal}
          handleCloseModal={closeModal}
          carName={props.carList.name}
          exvScore={carList.exvScore}>
        </Modal>
      )}
      {openMessage && (
        <MessageModal
          open={true}
          handleChange={handleMessageModal}>
        </MessageModal>
      )}

      <div className="upper-container">
        <div className="cr-img">
          <span className="classify-vl">
            {carList.classification === "BEV" ? (
              <i class="fa fa-bolt" style={{ fontSize: "12px" }}></i>
            ) : (
              <img src={ChargingPng} alt="#"></img>
            )}
            &nbsp;{carList.classification}
          </span>
          {carList.brandUrl ? (
            <a target="_blank" rel="noopener noreferrer" tabIndex="0"
               href={carList.brandUrl}>
              {" "}
              <img src={carList.images && carList.images[0][0]} alt="#" />
            </a>
          ) : (
            <img src={carList.images && carList.images[0][0]} alt="#" />
          )}
          <button tabIndex="0" type="button" className={(carList.bigButtonLink === "")?"drive-inactive":"drive-active"}
                  onClick={() => openUrlInNewTab(carList.bigButtonLink)}
                  disabled={(carList.bigButtonLink === "")}>
            {carList.bigButtonText}
          </button>
        </div>
        <div className="cr-img-02">
          <div className="cr-img-header">
            {carList.brandUrl ? (
              <a target="_blank" rel="noopener noreferrer" tabIndex="0"
                 href={carList.brandUrl}>
                {" "}
                <p>{props.carList.name}</p>
              </a>
            ) : (
              <p>{props.carList.name}</p>
            )}
          </div>
          <div className="cr-details"  >
            <div className="col-mdl-01">
              <table>
                <tbody>
                  <tr>
                    <th>WLTP Range</th>
                    <th>
                      Home Charge&nbsp;
                      <Tooltip arrow classes={classes} open={tooltipIsOpen}
                        onOpen={() => setTooltipIsOpen(true)}
                        onClose={() => setTooltipIsOpen(false)}
                        title="This is the time taken to charge
                        to 100% using the maximum home charge rate possible"
                        placement="top">
                        <span onBlur={() => setTooltipIsOpen(false)}></span>
                      </Tooltip>
                      <i class="fad fa-question-circle" style={{ cursor: "pointer" }} ref={ref}
                         onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                         onScroll={() => setTooltipIsOpen(false)}>
                      </i>
                    </th>
                  </tr>
                  <tr>
                    <td>{carList.range} km</td>
                    <td>{secondsToHms(carList.homeChargeTime)}</td>
                  </tr>
                  <tr>
                    <th>
                      100km Rapid Charge &nbsp;
                      <Tooltip arrow classes={classes} open={tooltipIsOpen2}
                        onOpen={() => setTooltipIsOpen2(true)}
                        onClose={() => setTooltipIsOpen2(false)}
                        title="This is the time taken to add 100 km of range at the maximum rapid charge rate."
                        placement="top">
                        <span></span>
                      </Tooltip>
                      <i class="fad fa-question-circle" style={{ cursor: "pointer" }} ref={ref2}
                         onClick={() => setTooltipIsOpen2(!tooltipIsOpen2)}
                         onScroll={() => setTooltipIsOpen(false)}>
                      </i>
                    </th>
                    <th>Price Category</th>
                  </tr>
                  <tr>
                    <td>
                      {carList.rapid100km
                        ? carList.rapid100km + " mins"
                        : "N/A"}
                    </td>
                    <td>{carList.priceCat}</td>
                  </tr>
                  <tr>
                    <td>
                      {(carList.status === "Coming soon" ) && (
                        <a role="button" target="_blank" rel="noopener noreferrer"
                           href={carList.searchUrl}>
                          <button tabIndex="0" type="button" className="view-car alloc-sold">
                            Register now
                          </button>
                        </a>
                      )}
                      {(carList.status === "Reservations open" ) && (
                          <a role="button" target="_blank" rel="noopener noreferrer"
                             href={carList.searchUrl}>
                            <button tabIndex="0" type="button" className="view-car reser-open">
                              Reserve now
                            </button>
                          </a>
                      )}
                      {(carList.status === "Order now" ) && (
                          <a role="button" target="_blank" rel="noopener noreferrer"
                             href={carList.searchUrl}>
                            <button tabIndex="0" type="button" className="view-car blue-car">
                              Order now
                            </button>
                          </a>
                      )}
                      {(carList.status === "Available Now" || carList.status === "Used only") && (
                          <a role="button" target="_blank" rel="noopener noreferrer"
                             href={carList.searchUrl}>
                            <button tabIndex="0" type="button" className="view-car blue-car">
                              View cars for sale
                            </button>
                          </a>
                      )}
                      {(carList.status === "Custom build" ) && (
                          <a role="button" target="_blank" rel="noopener noreferrer"
                             href={carList.searchUrl}>
                            <button tabIndex="0" type="button" className="view-car com-soon">
                              Custom build
                            </button>
                          </a>
                      )}
                    </td>
                    <td>
                      {carList.talkToOwner && (
                        <a tabIndex="0" role="button" target="_blank" rel="noopener noreferrer"
                          href={carList.talkToOwner}>
                          <button className="square-btn" tabIndex="0">
                            <img src={CommentImage} alt="talk to owner"></img>
                          </button>
                        </a>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-mdl-02">
              <CircularProgress
                progress={carList.exvScore}
                color="#2c91ea"
                backcolor="#eee7e7"
                value={75}
              />
              <button tabIndex="0"
                 className={isOpen ? "app__toggle--active" : "app__toggle"}
                 onClick={() => onToggle(!isOpen)}>
                <div className="app__toggle-text" aria-label={isOpen ? "close" : "open"}>
                  EV match
                </div>
                <div className="rotate90">
                  <svg className={isOpen ? "icon" : "icon--expanded"} viewBox="6 0 12 24">
                    <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`bottom-container ${
          isOpen ? "bottom-container--inactive" : "bottom-container--active"
        } collapse-css-transition app__collapse`}
        aria-hidden={isOpen ? "true" : "false"}>
        <table>
          <tbody>
            <tr>
              <th>Weekday Range</th>
              <th>Weekend Range</th>
              <th>Longest Trip</th>
              <th>7.2 kW Overnight?</th>
              <th>Home charger upgrade</th>
            </tr>
            <tr>
              <td>
                <Box mb={3} borderColor="transparent">
                  <Rating name="read-only"
                    value={carList.displayValues.weekdayRangeStars}
                    readOnly
                    max={3}
                    size="large"
                  />
                </Box>
              </td>
              <td>
                <Box mb={3} borderColor="transparent">
                  <Rating name="read-only"
                    value={carList.displayValues.weekendRangeStars}
                    readOnly
                    max={3}
                    size="medium"
                  />
                </Box>
              </td>
              <td>
                &nbsp;&nbsp;&nbsp;&nbsp;
                {carList.displayValues.longestTripFace === "smile" && (
                  <SentimentSatisfiedRoundedIcon fontSize="inherit" />
                )}
                {carList.displayValues.longestTripFace === "sad" && (
                  <SentimentDissatisfiedRoundedIcon fontSize="inherit" />
                )}
              </td>
              <td>
                &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {carList.displayValues.overnight === "smile" && (
                  <SentimentSatisfiedRoundedIcon fontSize="inherit" />
                )}
                {carList.displayValues.overnight === "sad" && (
                  <SentimentDissatisfiedRoundedIcon fontSize="inherit" />
                )}
              </td>
              <td className="mdl-col-2">
                {carList.displayValues.homeChargerUpgrade}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Card;
