import config from "../../config";

export const updateHomeLocation = (data) => ({
  type: "UPDATE_HOME_LOCATION",
  target: data,
});

export const updatePlaceObject = (data) => ({
  type: "UPDATE_PLACE_OBJECT",
  target: data,
});

export const updateFurthestLocation = (data) => ({
  type: "UPDATE_FURTHEST_LOCATION",
  target: data,
});

export const updateFurthestObject = (data) => ({
  type: "UPDATE_FURTHEST_OBJECT",
  target: data,
});

export const updateDistance = (data) => ({
  type: "UPDATE_DISTANCE",
  target: data,
});

export const updateActiveStep = (data) => ({
  type: "UPDATE_ACTIVE_STEP",
  target: data,
});

export const updatePreviousActiveStep = (data) =>({
    type: "UPDATE_PREVIOUS_ACTIVE_STEP",
    target: data,
})

export const updateWeekdayRange = (data) => ({
  type: "UPDATE_WEEKDAY_RANGE",
  target: data,
});

export const updatePerWeekdayTrip = (data) => ({
  type: "UPDATE_PERWEEKDAY_TRIP",
  target: data,
});

export const updateWeekendRange = (data) => ({
  type: "UPDATE_WEEKEND_RANGE",
  target: data,
});

export const updatePerWeekendTrip = (data) => ({
  type: "UPDATE_PERWEEKEND_TRIP",
  target: data,
});

export const parkingPlace = (data) => ({
  type: "UPDATE_PARKING_PLACE",
  target: data,
});

export const updateCarArray = (data) => ({
  type: "UPDATE_CAR_ARRAY",
  target: data,
});

export const updateCarType = (data) => ({
  type: "UPDATE_WHICH_CARTYPE",
  target: data,
});

export const updatePluginHybrid = (data) => ({
  type: "UPDATE_PLUGINHYBRID",
  target: data,
});

export const updateCarList = (data) => ({
  type: "UPDATE_CARLIST_RESPONSE",
  target: data,
});

export const updateTestLocation = (data) => ({
  type: "UPDTATE_TESTDRIVE_LOCATION",
  target: data,
});

export const updateHomeOrLandingPage = (data) =>({
  type:"UPDATE_HOMEORLANDINGPAGE",
  target:data
})

export const getCarList = (filter) => {
  return (dispatch) => {
    console.log(filter);
    let url = config.carListApi;
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filter),
    })
      .then((res) => {
        return res.json();
      })
      .then((response, error) => {
        dispatch(updateCarList(response));
      })

      .catch((error) => console.log(error));
  };
};

export const bookForTestDrive = (filter) => {
  return (dispatch) => {
    console.log(filter);
    let url = config.bookTestDriveApi;
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(filter),
    })
      .then((res) => {
        return res.json();
      })
      .then((response, error) => {
        console.log(response);
      })

      .catch((error) => console.log(error));
  };
};
