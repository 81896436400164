import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./stepper.scss";

const styles = (theme) => ({
  root: {
    width: "300px",
    color: "white",
    paddingTop: "30px",
  },
  step: {
    color: "white",
  },
  iconContainer: {
    color: "white",
    border: "1 px solid grey",
  },
});

//steps array
function getSteps() {
  return [
    "HOME LOCATION",
    "WEEKDAY RANGE",
    "WEEKEND RANGE",
    "FURTHEST DESTINATION",
    "OVERNIGHT CHARGE",
    "CAR TYPE",
  ];
}

//This component is to show the steps user reached in the filter

class VerticalLinearStepper extends React.Component {
  constructor(props) {
    console.log("constructor calleddd");
    super(props);
    this.state = {
      previousActiveStep: this.props.preActiveStep,
    };
  }

  //updating the circle test position
  componentDidMount() {
    if (document.getElementsByClassName("MuiStepIcon-active")[0]) {
      let ele = document.getElementsByClassName("MuiStepIcon-active")[0];
      ele.childNodes[1].setAttribute("y", "18");
    }
  }

  componentDidUpdate() {

    if (document.getElementsByClassName("MuiStepIcon-active")[0]) {
      let ele = document.getElementsByClassName("MuiStepIcon-active")[0];
      ele.childNodes[1].setAttribute("y", "18");
    }
  }

  //to handle the edit button in stepper by updating the required
  //active step
  handleEdit = (index) => {

    if (this.state.previousActiveStep < this.props.activeStep) {
      this.props.dispatch({
        type: "UPDATE_PREVIOUS_ACTIVE_STEP",
        target: this.props.activeStep,
      });

      this.setState({ previousActiveStep: this.props.activeStep }, () =>
        console.log(this.state.previousActiveStep)
      );
    }

    this.props.dispatch({ type: "UPDATE_ACTIVE_STEP", target: index });
    if (index === 0) {
      this.props.history.push("/search/home-location");
    } else if (index === 1) {
      this.props.history.push("/search/weekday-range");
    } else if (index === 2) {
      this.props.history.push("/search/weekend-range");
    } else if (index === 3) {
      this.props.history.push("/search/furthest-destination");
    } else if (index === 4) {
      this.props.history.push("/search/overnight-charge");
    } else if (index === 5) {
      this.props.history.push("/search/car-type");
    }
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.props;
    
    let suburb = [];

    let farSuburb = [];

    if (Object.keys(this.props.placeObject).length > 0) {
      suburb = this.props.placeObject.address_components.filter((ele) => {
        if (ele.types.includes("locality")) {
          return ele;
        }
      });
      //If locality is not present in the object
      if (suburb.length === 0) {
        suburb.push({
          short_name: this.props.placeObject.formatted_address.split(",")[0],
        });
      }
    }
    if (Object.keys(this.props.furthestObject).length > 0) {
      farSuburb = this.props.furthestObject.address_components.filter((ele) => {
        if (ele.types.includes("locality")) {
          return ele;
        }
      });
      //If locality is not present in the object
      if (farSuburb.length === 0) {
        farSuburb.push({
          short_name: this.props.furthestObject.formatted_address.split(",")[0],
        });
      }
    }

    console.log(suburb);

    //To store the values to show them below the active step or required step
    let arr = [
      suburb.length > 0 && suburb[0].short_name,
      this.props.perWeekdayTrip,
      this.props.perWeekendTrip,
      farSuburb.length > 0 && farSuburb[0].short_name,
      this.props.parkingPlace ? "Yes" : "No",
      "Edit",
    ];

    //It is used to store how many filters have been covered till now
    console.log(this.state.previousActiveStep, "ghghghghghghghgh");
    let stepperArray = [];
    if (this.state.previousActiveStep >= this.props.activeStep) {
      for (let i = 0; i < this.state.previousActiveStep; i++) {
        stepperArray.push(arr[i]);
      }
    }
    if (this.state.previousActiveStep < this.props.activeStep) {
      for (let i = 0; i < activeStep; i++) {
        stepperArray.push(arr[i]);
      }
    }

    return (
      <div className={classes.root}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          connector={false}
        >
          {steps.map((label, index) => {
            return (
              <Step key={label} className={classes.step}>
                <StepLabel
                  classes={{
                    iconContainer: classes.iconContainer,
                  }}
                >
                  {label}
                </StepLabel>
                <p className="stepper-entity">
                  {stepperArray[index] && stepperArray[index].slice(0, 24)}
                  {stepperArray[index] && (
                    <span
                      onClick={() => this.handleEdit(index)}
                      id={`edit-icon${index}`}
                    >
                      <EditIcon />
                    </span>
                  )}
                </p>
              </Step>
            );
          })}
        </Stepper>
      </div>
    );
  }
}

VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    activeStep: state.activeStep,
    preActiveStep: state.previousActiveStep,
    homeLocation: state.homeLocation,
    perWeekdayTrip: state.perWeekdayTrip,
    perWeekendTrip: state.perWeekendTrip,
    furthestLocation: state.furthestLocation,
    furthestObject: state.furthestObject,
    parkingPlace: state.parkingPlace,
    placeObject: state.placeObject,
  };
}

export default withRouter(
  connect(mapStateToProps)(withStyles(styles)(VerticalLinearStepper))
);
