import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import CarLogo from "../../../images/carlogo.png";
import { connect } from "react-redux";
import { updateDistance } from "../../../redux/actions";
import "./GoogleMap.scss";

let innerCircle, outerCircle, mapzoom;
class SimpleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latitude: -25.274398,
      longitude: 133.775136,
      radiusOuter: this.props.weekendRange * 1000,
      radiusInner: this.props.weekdayRange * 1000,
      zoom: 5,
      directions: null,
      error: null,
      destination: "",
      width: window.innerWidth,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.updateDimensions);
    const { placeObject } = this.props;
    //if location is not selected need to set whole australia
    if (this.props.homeLocation === "") {
      this.setState({ latitude: -25.274398, longitude: 133.775136 });
    }
    //if location is there then need to set according to that
    if (Object.keys(placeObject).length > 0) {
      const lat = placeObject.geometry.location.lat();
      const lng = placeObject.geometry.location.lng();
      let max = Math.max(this.props.weekdayRange, this.props.weekendRange);
      let newZoom;

      //to set the zoom level for each screen
      if (max < 30) {
        if (this.state.width < 767) {
          newZoom = 9;
        } else {
          newZoom = 10;
        }
      } else if (max >= 30 && max < 60) {
        if (this.state.width < 767) {
          newZoom = 8;
        } else {
          newZoom = 9;
        }
      } else if (max >= 60 && max < 75) {
        newZoom = 8;
      } else if (max >= 75 && max <= 100) {
        newZoom = 7;
      }
      this.setState({ latitude: lat, longitude: lng, zoom: newZoom });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  //for updating the current window width
  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidUpdate(nextProps, nextState) {
    window.scrollTo(0, 0);

    const { placeObject } = this.props;
    if (
      this.props.homeLocation !== nextProps.homeLocation &&
      Object.keys(placeObject).length > 0
    ) {
      const lat = placeObject.geometry.location.lat();
      const lng = placeObject.geometry.location.lng();
      this.setState({ latitude: lat, longitude: lng, zoom: 10 });
      this.setState({ radiusInner: 10000, radiusOuter: 12000 });
    }
    if (
      this.props.activeStep !== nextProps.activeStep ||
      this.props.weekdayRange !== nextProps.weekdayRange ||
      this.props.weekendRange !== nextProps.weekendRange
    ) {
      this.radiusUpdater();
    }

    if (this.props.furthestLocation !== nextProps.furthestLocation) {
      let newZoom;
      if (this.props.distanceBetweenHomeToFurthest <= 250) {
        newZoom = 9;
      } else if (
        this.props.distanceBetweenHomeToFurthest > 250 &&
        this.props.distanceBetweenHomeToFurthest <= 1000
      ) {
        newZoom = 8;
      } else {
        newZoom = 7;
      }
      this.setState({ destination: nextProps.furthestLocation, zoom: newZoom });
    }
    return this.props.homeLocation === nextProps.homeLocation;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.props.perWeekdayTrip !== nextProps.perWeekdayTrip ||
      this.props.perWeekendTrip !== nextProps.perWeekendTrip
    ) {
      return false;
    } else if (
      this.props.distanceBetweenHomeToFurthest !==
      nextProps.distanceBetweenHomeToFurthest
    ) {
      return false;
    } else {
      return true;
    }
  }

  radiusUpdater = () => {
    //inner and outer radius for home suburb page
    console.log("radiusupdater");
    if (this.props.activeStep === 0) {
      this.setState({ radiusInner: 10000, radiusOuter: 14000, zoom: 10 });
    } else if (this.props.activeStep === 1) {
      //inner and outer radius for weekday range page
      let radius = parseInt(this.props.weekdayRange * 1000);
      let max = this.props.weekdayRange;
      let newZoom;
      if (max < 30) {
        if (this.state.width < 767) {
          mapzoom.setZoom(9);
          newZoom = 9;
        } else {
          mapzoom.setZoom(10);
          newZoom = 10;
        }
      } else if (max >= 30 && max < 60) {
        if (this.state.width < 767) {
          mapzoom.setZoom(8);
          newZoom = 8;
        } else {
          mapzoom.setZoom(9);
          newZoom = 9;
        }
      } else if (max >= 60 && max < 75) {
        newZoom = 8;
        mapzoom.setZoom(8);
      } else if (max >= 75 && max <= 100) {
        newZoom = 7;
        mapzoom.setZoom(7);
      }

      this.setState({
        radiusInner: radius,
        radiusOuter: 0,
        zoom: newZoom,
      });
      innerCircle.setRadius(Number(radius));
      outerCircle.setRadius(Number(0));
    } else if (this.props.activeStep === 2) {
      //for weekend day range page need to set outer radius
      let radius = parseInt(this.props.weekendRange * 1000);

      let max = Math.max(this.props.weekdayRange, this.props.weekendRange);
      let newZoom;
      //set the zoom level for each screen
      if (max < 30) {
        if (this.state.width < 767) {
          mapzoom.setZoom(9);
          newZoom = 9;
        } else {
          mapzoom.setZoom(10);
          newZoom = 10;
        }
      } else if (max >= 30 && max < 60) {
        if (this.state.width < 767) {
          mapzoom.setZoom(8);
          newZoom = 8;
        } else {
          mapzoom.setZoom(9);
          newZoom = 9;
        }
      } else if (max >= 60 && max < 75) {
        newZoom = 8;
        mapzoom.setZoom(8);
      } else if (max >= 75 && max <= 100) {
        newZoom = 7;
        mapzoom.setZoom(7);
      }

      this.setState({
        radiusOuter: radius,
        zoom: newZoom,
      });
      outerCircle.setRadius(Number(radius));
    } else if (this.props.activeStep === 3) {
      //for furthest destination page
      let radius = parseInt(this.props.weekendRange * 1000);
      let newZoom;
      if (this.props.distanceBetweenHomeToFurthest <= 250) {
        newZoom = 9;
      } else if (
        this.props.distanceBetweenHomeToFurthest > 250 &&
        this.props.distanceBetweenHomeToFurthest <= 1000
      ) {
        newZoom = 7;
      } else {
        newZoom = 6;
      }
      this.setState({
        radiusOuter: radius,
        zoom: newZoom,
      });
      outerCircle.setRadius(Number(radius));
    }
  };

  handleDistance = (result) => {
    //to save the distance for furthest location
    this.props.changeDistanceToBeCovered(result.routes[0].legs[0].distance);
  };

  render() {
    const { activeStep } = this.props;
    let defaultProps = {
      center: {
        lat: this.state.latitude,
        lng: this.state.longitude,
      },
      set: {
        lat: this.state.latitude,
        lng: this.state.longitude,
      },
      zoom: activeStep === 3 ? 8 : this.state.zoom,
    };

    const apiIsLoaded = (map, maps) => {
      mapzoom = map;
      outerCircle = new maps.Circle({
        strokeColor: activeStep === 0 ? "white" : "#2C91EA",
        strokeOpacity: this.props.activeStep !== 3 ? 0.8 : 0,
        strokeWeight: 2,
        fillColor: "#5cabee",
        fillOpacity: 0.2,
        map,
        center: defaultProps.center,
        radius: activeStep === 1 ? 0 : this.state.radiusOuter,
      });

      innerCircle = new maps.Circle({
        strokeColor: activeStep >= 2 ? "#5cabee" : "#2C91EA",
        strokeOpacity: this.props.activeStep !== 3 ? 0.8 : 0,
        strokeWeight: this.props.activeStep < 2 ? 2 : 0,
        fillColor: activeStep >= 2 ? "#2C91EA" : "#5cabee",
        fillOpacity: 0.3,
        map,
        center: defaultProps.center,
        radius: this.state.radiusInner,
      });
      if (
        this.props.activeStep === 3 &&
        this.props.furthestLocation &&
        this.props.furthestLocation !== "Australia"
      ) {
        var directionsRenderer = new maps.DirectionsRenderer();
        const directionsService = new maps.DirectionsService();
        directionsRenderer.setMap(map);
        directionsService.route(
          {
            origin: this.props.homeLocation,
            destination: this.props.furthestLocation,
            travelMode: maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === maps.DirectionsStatus.OK) {
              //have to set new zoom ratio on every km change for weekend and weekday range
              this.handleDistance(result);
              //to render the route result
              directionsRenderer.setDirections(result);
              //to preserve the zoom ratio and not to display extra markers on map
              directionsRenderer.setOptions({
                preserveViewport: true,
                suppressMarkers: true,
                strokeWeight: 3,
              });
              //to add a marker at the end of the route
              var leg = result.routes[0].legs[0];
              makeMarker(leg.end_location, maps.Point(10, 12), "title");
              function makeMarker(position, icon, title) {
                new maps.Marker({
                  position: position,
                  map: map,
                  icon: icon,
                  title: title,
                });
              }
            } else {
              this.setState({ error: result });
            }
          }
        );
      }
    };
    if (this.state.latitude && this.state.longitude) {
      return (
        // Important! Always set the container height explicitly
        <div
          className="map-container"
          id="map-id"
          style={{ height: "100vh", width: "100%" }}
          key={
            this.props.activeStep +
            this.props.activeStep +
            this.props.activeStep +
            this.props.activeStep +
            this.props.homeLocation +
            this.props.activeStep +
            this.props.homeLocation +
            this.state.latitude +
            this.state.longitude +
            this.state.destination +
            this.props.furthestLocation
          }
        >
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_API_KEY,
            }}
            defaultCenter={defaultProps.set}
            defaultZoom={this.state.zoom}
            yesIWantToUseGoogleMapApiInternals
            fullscreenControl={true}
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
          >
            <img
              lat={defaultProps.center.lat}
              lng={defaultProps.center.lng}
              style={{
                width: "40px",
                height: "40px",
                marginTop: "-20px",
                marginLeft: "-20px",
              }}
              src={CarLogo}
              alt="#"
            />
          </GoogleMapReact>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    homeLocation: state.homeLocation,
    weekdayRange: state.weekdayRange,
    weekendRange: state.weekendRange,
    perWeekdayTrip: state.perWeekdayTrip,
    perWeekendTrip: state.perWeekendTrip,
    activeStep: state.activeStep,
    furthestLocation: state.furthestLocation,
    placeObject: state.placeObject,
    distanceBetweenHomeToFurthest: state.distanceBetweenHomeToFurthest,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeDistanceToBeCovered: (props) => dispatch(updateDistance(props)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SimpleMap);
