import React, { useState, useEffect, useRef } from "react";
import "./Search.scss";
import Locationpng from "../../images/locationicon.png";
import { connect } from "react-redux";
import {
  updateHomeLocation,
  updateFurthestLocation,
  updateFurthestObject,
  updatePlaceObject,
  updateTestLocation,
} from "../../redux/actions";
import { Redirect } from "react-router";

let autoComplete;

function SearchLocationInput(props) {
  const [query, setQuery] = useState(props.location ? props.location : "");
  const [redirect] = useState(false);
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    handleScriptLoad(setQuery, autoCompleteRef);
  }, []);

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ["geocode"], componentRestrictions: { country: "aus" } }
    );
    autoComplete.setFields([
      "address_components",
      "geometry",
      "formatted_address",
    ]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    setQuery(query);
    console.log(addressObject);
    if (props.update === "homeLocation") {
      if (Object.keys(addressObject).length > 1) {
        props.changePlaceObject(addressObject);
        props.changeTestLocation(addressObject);
        props.changeHomeLocation(
          addressObject.formatted_address
            ? addressObject.formatted_address
            : addressObject.name
        );
      } else {
        props.changePlaceObject({});
        props.changeTestLocation({});
      }
     
    }
    if (props.update === "furthestLocation") {
      if (Object.keys(addressObject).length > 1) {
      props.changeFurthestLocation(addressObject.formatted_address);
      props.changeFurthestObject(addressObject);
      }else{

      }
    }
    if (props.update === "testDriveLocation") {
      if (Object.keys(addressObject).length > 1) {
        props.changeTestLocation(addressObject);
      } else {
        props.changeTestLocation({});
      }
    }
  }

  //to handle the input change
  const handleChange = (e) => {
    setQuery(e.target.value);
    if (
      props.update === "homeLocation" &&
      e.target.value === "" &&
      props.activeStep < 5
    ) {
      props.changeHomeLocation(e.target.value);
    }
    if (props.update === "testDriveLocation" && e.target.value === "") {
      props.changeTestLocation({});
    }
  };

  return (
    <React.Fragment>
      {redirect && <Redirect to="/search" push></Redirect>}
      <div className="search-location-input">
        <input
          ref={autoCompleteRef}
          onChange={(event) => handleChange(event)}
          placeholder={props.placeholder}
          value={query}
          tabIndex="0"
        />
        <img src={Locationpng} className="loc-img" alt="#"></img>
      </div>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    homeLocation: state.homeLocation,
    furthestLocation: state.furthestLocation,
    activeStep: state.activeStep,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeHomeLocation: (props) => dispatch(updateHomeLocation(props)),
    changeFurthestLocation: (props) => dispatch(updateFurthestLocation(props)),
    changeFurthestObject: (props) => dispatch(updateFurthestObject(props)),
    changePlaceObject: (props) => dispatch(updatePlaceObject(props)),
    changeTestLocation: (props) => dispatch(updateTestLocation(props)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchLocationInput);
