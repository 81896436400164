import React from "react";
import LeftPanel from "./LeftSection";
import RightPanel from "./RightSection";
import "./MainSection.scss";
import GoogleMapReact from "./googlemap/Map";
import { connect } from "react-redux";
import "./Fold.scss";
import Logo from "../../images/findmyev_logo_128x34.svg";
import LogoMobile from "../../images/findmyev_logo_106x28.svg";
import { useEffect, useRef,} from "react";


//menubutton for header also includes close icon
class MenuButton extends React.Component {
  render() {
    var containerClasses = ["container"];
    if (!this.props.state) {
      containerClasses.push("close");
    }
    return (
      <span className={containerClasses.join(" ")} id="close-icon1">
        <div className={"bar1"} id="close-icon2"></div>
        <div className={"bar2"} id="close-icon3"></div>
        <div className={"bar3"} id="close-icon4"></div>
      </span>
    );
  }
}

//in mainpanel we have to main components
//the right and left panel
//right panel has all filters related page like home suburb and weekday range
//left panel has stepper in it
//and it has a header also for smaller resolution
function Mainpanel(props) {
  const [open, setOpen] = React.useState(false);
  const ref = useRef(null);
  const [width, setWidth] = React.useState(window.innerWidth);

 

  React.useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", updateDimensions);
  }, []);

  //for updating the current window width
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  //to handle the open or close of hamburger menu
  const changeNav = () => {
    console.log("working", open);
    setOpen(!open);
  };

  // //to handle the outside click on slider
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      console.log("working1", open, event.srcElement);

      if (
        event.target.id !== "close-icon1" &&
        event.target.id !== "close-icon" &&
        event.target.id !== "close-icon2" &&
        event.target.id !== "close-icon3" &&
        event.target.id !== "close-icon4" &&
        event.target.id !== "edit-icon0" &&
        event.target.id !== "edit-icon1" &&
        event.target.id !== "edit-icon2" &&
        event.target.id !== "edit-icon3" &&
        event.target.id !== "edit-icon4" &&
        event.target.id !== "edit-icon5"
      ) {
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  //to reload the page
  const reloadPage = () => {
    
    window.location.reload();
    
  };

  var containerClasses = [];
  if (open) {
    containerClasses.push("open");
  }
  return (
    <div className="row-1-panel">
      <div className="header-container">
        <div className={containerClasses.join(" ")}>
          <div
            className={"menubutton"}
            id="close-icon"
            onClick={() => changeNav()}
            value="1"
          >
            <MenuButton state={!open} />
          </div>
          <div className="logo-section">
            {width < 576 && (
              <img
                src={LogoMobile}
                onClick={() => reloadPage()}
                style={{ cursor: "pointer" }}
                alt="#"
              ></img>
            )}
            {width >= 576 && (
              <img
                src={Logo}
                onClick={() => reloadPage()}
                style={{ cursor: "pointer" }}
                alt="#"
              ></img>
            )}
          </div>
          <div className="progress-bar-header">
            <div
              className="filler"
              style={{
                width: `${
                  props.activeStep === 0 ? 4 : props.activeStep * 16.66
                }%`,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="panel-container">
        <div className="left-panel">
          <div className={containerClasses.join(" ")}>
            <div className={"sidenav"} ref={ref}>
              <LeftPanel change={changeNav}></LeftPanel>
            </div>
          </div>
        </div>
        <div className="right-panel" id="right-container">
          <RightPanel></RightPanel>
          {props.activeStep <= 3 && <GoogleMapReact></GoogleMapReact>}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    activeStep: state.activeStep,
  };
}
export default connect(mapStateToProps)(Mainpanel);
