import React from "react";
import Stepper from "./stepper";
import Logo from "../../images/findmyev_logo_128x34.svg";
import TouchModal from "./result/ContactModal";
import MessageModal from "./result/MessageModal";

//This component mainly contains the stepper in it for all the steps we have covered
//it has home suburb, weekday range, weekend range, overnight charges,
//cartype and at last car result

function Leftpanel(props) {
  const [openTouchModal, setTouchModal] = React.useState(false);
  const [openMessage, setOpenMessage] = React.useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);

  //for updating the current window width
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  });

  //handle get in touch modal for contact us
  const handleTouchModal = (bool) => {
    setTouchModal(bool);
    setOpenMessage(!bool);
  };

  //for closing get in touch modal for contact us
  const closeModal = (bool) => {
    setTouchModal(bool);
  };

  //to handle after successfull of get in touch modal, message modal
  const handleMessageModal = (bool) => {
    setOpenMessage(bool);
  };

  //to open the contact modal 
  const openContactModal = () => {
    setTouchModal(true);
    if (width < 1200) {
      props.change();
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div
      style={{ position: "relative", height: "100%" }}
      id="menu"
      key={`opentouchmodal${(openContactModal, openTouchModal)}`}
    >
      {openTouchModal && (
        <TouchModal
          open={true}
          handleChange={handleTouchModal}
          handleCloseModal={closeModal}
        ></TouchModal>
      )}
      {openMessage && (
        <MessageModal
          open={true}
          handleChange={handleMessageModal}
          message="Keep an eye out for an email from us"
        ></MessageModal>
      )}
      <div className="logo-section">
        <img
          src={Logo}
          onClick={() => reloadPage()}
          style={{ cursor: "pointer" }}
        ></img>
      </div>
      <Stepper></Stepper>
      <div className="cont-us">
        <button
          onClick={() => openContactModal()}
          tabIndex="-1"
          id="contact-button"
        >
          Contact Us
        </button>
      </div>
    </div>
  );
}

export default Leftpanel;
