import React from "react";
import HomeLocation from "./slidercomponent/HomeSuburb";
import WeekdayRange from "./slidercomponent/Weekday";
import WeekendRange from "./slidercomponent/Weekend";
import FurthestDestination from "./slidercomponent/FurthestLocation";
import OverNightCharges from "./slidercomponent/OvernightChargePage";
import CarType from "./slidercomponent/Type";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { updateActiveStep, getCarList } from "../../redux/actions";
import CarResult from "./result/Result";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router-dom";

//steps array 
function getSteps() {
  return [
    "HOME LOCATION",
    "WEEKDAY RANGE",
    "WEEKEND RANGE",
    "FURTHEST DESTINATION",
    "OVERNIGHT CHARGE",
    "CAR TYPE",
  ];
}

//rightpanel mainly has different routes for different filter page
//routing is enabled according to the required active step we having
//active step 0 indicates home suburb page
//active step 1 indicates weekday range page
//active step 2 indicates weekend day page
//active step 3 indicates furthest destination page
//active step 4 indicates overnight charge page
//active step 5 indicates car type page

class Rightpanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      displayResult: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    //on clicking on edit button from stepper we need to change the active step and also for browser back and forward button
    //according to the current route we are setting active step
    window.onpopstate = (e) => {
      let path = window.location.pathname;
      console.log(path);
      if (path === "/search/home-location") {
        this.props.nextActiveStep(0);
      } else if (path === "/search/weekday-range") {
        this.props.nextActiveStep(1);
      } else if (path === "/search/weekend-range") {
        this.props.nextActiveStep(2);
      } else if (path === "/search/furthest-destination") {
        this.props.nextActiveStep(3);
      } else if (path === "/search/overnight-charge") {
        this.props.nextActiveStep(4);
      } else if (path === "/search/car-type") {
        this.props.nextActiveStep(5);
      } else if (path === "/search/results") {
        console.log("browser button");
        this.props.nextActiveStep(6);
      }
    };
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  //to handle the next button and to handle the api calling after
  //completing all the filters for searching EV
  //here we have used tripmultiplier for setting the required range
  //for r1: selected trip multiplier * weekday range
  //for r2: selected trip multiplier * weekend range
  //for r3: distance between home to furthest location * 2 for return trip

  handleNext = () => {
    const { activeStep } = this.props;
    if (this.props.activeStep === 5) {
      let tripMultiplier = {
        "Single return trip": 2,
        "Back and forth": 3.5,
        "All over the place": 6,
      };
      let filter = {
        homeCharge: this.props.parkingPlace,
        r1: parseInt(
          this.props.weekdayRange * tripMultiplier[this.props.perWeekdayTrip]
        ),
        r2: parseInt(
          this.props.weekendRange * tripMultiplier[this.props.perWeekendTrip]
        ),
        r3: parseInt(
          (this.props.distanceBetweenHomeToFurthest.value / 1000) * 2
        ),
        bodyStyle: this.props.carTypeArray.toString(),
        newOrUsed: this.props.whichCarType,
        suburb: this.props.placeObject,
        classification: this.props.plugInHybrid,
      };
      if (
        this.props.carTypeArray.length > 0 &&
        Object.keys(this.props.placeObject).length > 0 &&
        filter.r3 &&
        filter.r1 &&
        filter.r2
      )
        this.props.carListRequest(filter);
    }

    this.props.nextActiveStep(this.props.activeStep + 1);
    {
      activeStep + 1 === 1 &&
        window.location.pathname !== "/search/weekday-range" &&
        this.props.history.push("/search/weekday-range");
    }
    {
      activeStep + 1 === 2 && this.props.history.push("/search/weekend-range");
    }
    {
      activeStep + 1 === 3 &&
        this.props.history.push("/search/furthest-destination");
    }
    {
      activeStep + 1 === 4 &&
        this.props.history.push("/search/overnight-charge");
    }
    {
      activeStep + 1 === 5 && this.props.history.push("/search/car-type");
    }
    {
      activeStep + 1 === 6 && this.props.history.push("/search/results");
    }
  };

  //handles the back button by reducing the active step
  handleBack = () => {
    if (this.props.activeStep === 0) {
      this.props.history.push("/");
    } else if (this.props.activeStep === 1) {
      this.props.history.push("/search/home-location");
    } else {
      this.props.history.goBack();
    }
    this.props.nextActiveStep(this.props.activeStep - 1);
  };

  render() {
    const { activeStep } = this.props;

    //Active array stores the values for all filter
    //It is used for next button enable for disable state

    let activeArray = [
      this.props.homeLocation,
      this.props.weekdayRange,
      this.props.weekendRange,
      this.props.furthestLocation &&
        this.props.furthestLocation !== this.props.homeLocation,
      true,
      this.props.carTypeArray.length > 0,
    ];
    const steps = getSteps();
    return (
      <div>
        {this.props.homeLocation === "" &&
          window.location.pathname !== "/search/home-location" && (
            <Redirect to="/"></Redirect>
          )}
        {activeStep <= 5 ? (
          <div className="upper-panel">
            <div className="progress-bar">
              <div
                className="filler"
                style={{
                  width: `${activeStep === 0 ? 4 : activeStep * 16.66}%`,
                }}
              ></div>
            </div>
            <div className="step-out-of-6">
              <p>STEP {activeStep + 1} of 6</p>
            </div>
            <Switch>
              <Route
                path="/search/home-location"
                component={() => <HomeLocation></HomeLocation>}
              ></Route>
              <Route
                path="/search/weekday-range"
                component={() => <WeekdayRange></WeekdayRange>}
              ></Route>
              <Route
                path="/search/weekend-range"
                component={() => <WeekendRange></WeekendRange>}
              ></Route>
              <Route
                path="/search/furthest-destination"
                component={() => <FurthestDestination></FurthestDestination>}
              ></Route>
              <Route
                path="/search/overnight-charge"
                component={() => <OverNightCharges></OverNightCharges>}
              ></Route>
              <Route
                path="/search/car-type"
                component={() => <CarType></CarType>}
              ></Route>
            </Switch>

            <div>
              <div className="button-container">
                <button
                  onClick={this.handleBack}
                  className="back-button"
                  tabIndex="0"
                  style={{
                    opacity: activeStep !== 0 ? "1" : "0",
                    cursor: activeStep === 0 && "default",
                  }}
                  disabled={activeStep === 0}
                >
                  <i className="fal fa-chevron-left fa-sm"></i>&nbsp;Back
                </button>
                <button
                  disabled={!activeArray[activeStep]}
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                  className={
                    activeArray[activeStep] ? "nex-button" : "disabled-button"
                  }
                  tabIndex="0"
                >
                  {activeStep === steps.length - 1 ? "Find my EV" : "Next"}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <Switch>
              <Route
                path="/search/results"
                component={() => <CarResult />}
              ></Route>
            </Switch>
          </React.Fragment>
        )}
      </div>
    );
  }
}

Rightpanel.propTypes = {
  classes: PropTypes.object,
};
function mapStateToProps(state) {
  return {
    activeStep: state.activeStep,
    homeLocation: state.homeLocation,
    perWeekdayTrip: state.perWeekdayTrip,
    perWeekendTrip: state.perWeekendTrip,
    furthestLocation: state.furthestLocation,
    parkingPlace: state.parkingPlace,
    distanceBetweenHomeToFurthest: state.distanceBetweenHomeToFurthest,
    weekdayRange: state.weekdayRange,
    weekendRange: state.weekendRange,
    carTypeArray: state.carTypeArray,
    whichCarType: state.whichCarType,
    plugInHybrid: state.plugInHybrid,
    placeObject: state.placeObject,
    carListResponse: state.carListResponse,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    nextActiveStep: (prop) => dispatch(updateActiveStep(prop)),
    carListRequest: (prop) => dispatch(getCarList(prop)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Rightpanel)
);
