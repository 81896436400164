import React from "react";
import { connect } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import { withRouter } from "react-router-dom";
import {
  updateWeekdayRange,
  updateActiveStep,
  updatePerWeekdayTrip,
} from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: "240px",
    ["@media (min-width:320px)"]: {
      minWidth: "240x",
      padding: "10px",
    },
    ["@media (min-width:576px)"]: {
      minWidth: "240px",
    },
    ["@media (min-width:768px)"]: {
      minWidth: "310px",
    },
    ["@media (min-width:1200px)"]: {
      minWidth: "310px",
    },
    border: "1px solid rgba(136,139,149,0.5)",
    borderRadius: "5px",
    fontFamily: "DM Sans",
    backgroundColor: "#FFFFFF",
    padding: "7px",
    fullWidth: true,
    display: "flex",
    wrap: "nowrap",
  },
  inputFocused: {
    borderColor: "#80bdff",
  },
}));

const PrettoSlider = withStyles({
  root: {
    color: "#D0D5DE",
    height: 4,
    marginTop: "2.3%",
    ["@media (min-width:320px)"]: {
      marginTop: "1.5%",
    },
    ["@media (min-width:576px)"]: {
      marginTop: "1%",
    },
    ["@media (min-width:768px)"]: {
      marginTop: "0.8%",
    },
    ["@media (min-width:992px)"]: {
      marginTop: "0.8%",
    },
    ["@media (min-width:1200px)"]: {
      marginTop: "1.2%",
    },
    ["@media (min-width:1400px)"]: {
      marginTop: "2.5%",
    },
  },
  thumb: {
    height: 30,
    width: 30,
    backgroundColor: "#2C91EA",
    border: "3px solid #fff",
    marginTop: -12,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 4,
    borderRadius: 4,
    color: "#2C91EA",
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    fontSize: 16,
    padding: "6px 0 7px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

//component for week day range with filters as a range slider and
//a trip multiplier dropdown
function WeekdayRange(props) {
  const classes = useStyles();
  React.useEffect(() => {
    window.scrollTo(0, 0);

    document.title = "Find my EV – What is your weekday range?";
    document.querySelector("meta[name='robots']").content = "noindex";
     //added meta tags for weekday range page
    document.querySelector("meta[property='og:title']").content =
      "Find my EV – What is your weekday range?";
    document.querySelector("meta[property='og:description']").content =
      "How far do you drive on a typical weekday?";
    document.querySelector("meta[name='twitter:description']").content =
      "How far do you drive on a typical weekday?";
    document.querySelector("meta[name='description']").content =
      "How far do you drive on a typical weekday?";
  }, []);

  const handleChange = (event) => {
    props.changePerWeekday(event.target.value);
  };

  const debounce = (func, delay) => {
    let inDebounce;
    return function (value) {
      clearTimeout(inDebounce);
      inDebounce = setTimeout(() => func(value), delay);
    };
  };
  
  let betterFunction = debounce(props.changeWeekdayRange, 500);

  const handleSlider = (e, value) => {
    betterFunction(value);
  };

  return (
    <div className="hm-header">
      <h4>
        How far do you travel on a <b>weekday?</b>
      </h4>
      <p className="com-sch">Commute, school, shopping.</p>
      <p className="use-the-slider">
        Move the slider to set your typical weekday range on the map and then
        select a drop down that reflects your typical day.
      </p>
      <div className="week-slider">
        <PrettoSlider
          valueLabelDisplay="auto"
          aria-label="pretto slider"
          defaultValue={props.range}
          onChange={(e, value) => handleSlider(e, value)}
        />

        <div className="slid-val">{props.range}&nbsp;km</div>
      </div>
      <div className="week-droop">
        <FormControl
          className={classes.formControl}
          classes={{ focused: classes.inputFocused }}
          tabIndex="0"
        >
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={props.perWeekdayRange}
            onChange={handleChange}
            input={<BootstrapInput />}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "sub",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="Single return trip">Single return trip</MenuItem>
            <MenuItem value="Back and forth">Back and forth</MenuItem>
            <MenuItem value="All over the place">All over the place</MenuItem>
          </Select>
        </FormControl>
        <p>within this range</p>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    range: state.weekdayRange,
    perWeekdayRange: state.perWeekdayTrip,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changePerWeekday: (prop) => dispatch(updatePerWeekdayTrip(prop)),
    changeWeekdayRange: (prop) => dispatch(updateWeekdayRange(prop)),
    nextActiveStep: (prop) => dispatch(updateActiveStep(prop)),
  };
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WeekdayRange)
);
