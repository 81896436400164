import React from "react";
import "./Progress.scss";


class CircularProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // Size of the enclosing square
    const sqSize = this.props.sqSize;
    // SVG centers the stroke width on the radius, subtract out so circle fits in square
    const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
    // Enclose cicle in a circumscribing square
    const viewBox = `0 0 ${sqSize} ${sqSize}`;
    // Arc length at 100% coverage is the circle circumference
    const dashArray = radius * Math.PI * 2;
    // Scale 100% coverage overlay with the actual percent
    const dashOffset = dashArray - (dashArray * this.props.percentage) / 100;

    return (
      <svg width="70%" height="auto" viewBox={viewBox}>
        <circle
          className="circle-background"
          stroke={this.props.backcolor}
          cx={this.props.sqSize / 2}
          cy={this.props.sqSize / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
        />
        <circle
          className="circle-progress"
          stroke={this.props.color}
          cx={this.props.sqSize / 2}
          cy={this.props.sqSize / 2}
          r={radius}
          strokeWidth={`${this.props.strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${this.props.sqSize / 2} ${
            this.props.sqSize / 2
          })`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
        <text
          className="circle-text"
          x="50%"
          y="55%"
          dy=".2em"
          textAnchor="middle"
        >
          {`${
            isNaN(this.props.value) ? this.props.value : this.props.percentage
          }`}
        </text>
      </svg>
    );
  }
}

CircularProgressBar.defaultProps = {
  sqSize: 150,
  percentage: 25,
  strokeWidth: 6,
};

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      percentage: 0,
    };

    this.handleChangeEvent = this.handleChangeEvent.bind(this);
  }
  componentDidMount() {
    let value = 10;
    const timer = setInterval(() => {
      this.setState((prevState) => {
        while (prevState.percentage < this.props.progress)
          return { percentage: this.state.percentage + 1 };
      });
    }, 40);
    return () => {
      clearInterval(timer);
    };
  }

  //to handle the event change 
  handleChangeEvent(value) {
    this.setState({
      percentage: value,
    });
  }

  render() {
    return (
      <div>
        <CircularProgressBar
          strokeWidth="12"
          sqSize="150"
          percentage={parseInt(this.props.progress)}
          color={this.props.color}
          backcolor={this.props.backcolor}
          value={this.props.value}
        />
      </div>
    );
  }
}
