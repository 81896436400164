import React from "react";
import { connect } from "react-redux";
import { parkingPlace } from "../../../redux/actions";
import { withRouter } from "react-router-dom";

//component for overnight charge and to get the value for parking place or not
function OvernightCharges(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.getElementById("right-container").scrollTo(0, 0);
    document.title = "Find my EV – Could you charge overnight?";
    //added meta tags for overnight charge page 
    document.querySelector("meta[name='robots']").content = "noindex";
    document.querySelector("meta[property='og:title']").content =
      "Find my EV – Could you charge overnight?";
    document.querySelector("meta[property='og:description']").content =
      "Do you have a place where you could install a car charger? Garage, off-street parking or carport.";
    document.querySelector("meta[name='twitter:description']").content =
      "Do you have a place where you could install a car charger? Garage, off-street parking or carport.";
    document.querySelector("meta[name='description']").content =
      "Do you have a place where you could install a car charger? Garage, off-street parking or carport. ";
  }, []);

  const handleChange = (bool) => {
    props.changeParkingPlace(bool);
  };

  return (
    <div className="hm-header">
      <h4>
        Do you have a <b>parking place</b> where an EV<br></br> charger could be
        installed?
      </h4>
      <p className="you-have-somewhere-w">
        You have somewhere where you would be able to park your car where your
        own charger could be installed. Garage, carport, apartment car space.
      </p>
      <div className="rectangle-button">
        <button
          tabIndex="0"
          className={props.parkingPlace ? "button-active" : "button-inactive"}
          onClick={() => handleChange(true)}
        >
          Yes
        </button>
        <button
          tabIndex="0"
          className={
            props.parkingPlace ? "button-right-inactive" : "button-right-active"
          }
          onClick={() => handleChange(false)}
        >
          No
        </button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    parkingPlace: state.parkingPlace,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeParkingPlace: (prop) => dispatch(parkingPlace(prop)),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OvernightCharges)
);
